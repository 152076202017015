import React, { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';

import Section from '@components/Section';
import Icons from '@icons';
import mediaqueries from '@styles/media';
import { IAuthor } from '@types';

const authorQuery = graphql`
  {
    site: allSite {
      edges {
        node {
          siteMetadata {
            hero {
              heading
              projectsHeading
              freelanceHeading
              maxWidth
              resumePath
            }
          }
        }
      }
    }
  }
`;

const ArticlesHero: React.FC<IAuthor> = ({ authors, hideHeader}) => {

  const results = useStaticQuery(authorQuery);
  const hero = results.site.edges[0].node.siteMetadata.hero;
  const featuredAuthor = authors.find(author => author.featured);

  if (!featuredAuthor) {
    throw new Error(`
      No featured Author found.
      Please ensure you have at least featured Author.
  `);
  }
  return (
    <Section relative id="Articles__Hero">
      {hideHeader != true &&
        <HeadingContainer style={{ maxWidth: `${hero.maxWidth}px` }}>
          <HeroHeading>Hi! I'm <HeroHeadingAccent>Andrew.</HeroHeadingAccent></HeroHeading>
          <HeroHeading>{hero.heading}</HeroHeading>
          {/* <BioText>{featuredAuthor.bio}</BioText> */}

          <ButtonsContainer>
            <Button onClick={function() { window.location = hero.resumePath }}>View Résumé</Button>
          </ButtonsContainer>
        </HeadingContainer>
      }

      <SubheadingContainer>
        <HeroSubHeading dangerouslySetInnerHTML={{ __html: !hideHeader ? hero.projectsHeading : hero.freelanceHeading }} />
      </SubheadingContainer>
    </Section>
  );
};

export default ArticlesHero;

const SubheadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;

  ${mediaqueries.desktop`
    margin-bottom: 50px;
  `};

  ${mediaqueries.tablet`
    margin-bottom: 50px;
  `};

  ${mediaqueries.phablet`
    margin-bottom: 40px;
  `};
`;

const GridControlsContainer = styled.div`
  display: flex;
  align-items: center;

  ${mediaqueries.tablet`
    display: none;
  `};
`;

const HeadingContainer = styled.div`
  margin: 100px 0;

  ${mediaqueries.desktop`
    width: 80%;
  `}

  ${mediaqueries.tablet`
    width: 100%;
  `}
`;

const ButtonsContainer = styled.div`
  margin-top: 40px;
`;

const HeroHeading = styled.h1`
  font-family: ${p => p.theme.fonts.serif};
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 1.5;
  color: ${p => p.theme.colors.primary};

  a {
    color: ${p => p.theme.colors.accent};
  }

  ${mediaqueries.desktop`
    font-size: 34px
  `}

  ${mediaqueries.phablet`
    font-size: 28px;
  `}
`;

const HeroHeadingAccent = styled.span`
  color: ${p => p.theme.colors.accent};
`;

const HeroSubHeading = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.15;
  color: #73737D;
  a {
    color: ${p => p.theme.colors.accent};
  }
  ${mediaqueries.desktop`
    font-size: 20px
  `}
  ${mediaqueries.phablet`
    font-size: 18px;
  `}
`;

const GridButton = styled.button<{ active: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background: transparent;
  transition: background 0.25s;

  &:not(:last-child) {
    margin-right: 30px;
  }

  &:hover {
    background: ${p => p.theme.colors.hover};
  }

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: -10%;
    top: -10%;
    width: 120%;
    height: 120%;
    border: 2px solid ${p => p.theme.colors.accent};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 50%;
  }

  svg {
    opacity: ${p => (p.active ? 1 : 0.25)};
    transition: opacity 0.2s;

    path {
      fill: ${p => p.theme.colors.primary};
    }
  }
`;

const Button = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 30px 12px 30px;
  border: 2px solid
    ${p => (p.hasError ? p.theme.colors.error : p.theme.colors.accent)};
  color: ${p => (p.hasError ? p.theme.colors.error : p.theme.colors.accent)};
  background: ${p => (p.subscribed ? p.theme.colors.accent : "transparent")};
  font-weight: 600;
  border-radius: 35px;
  letter-spacing: 0.42px;
  transition: border-color 0.2s var(--ease-in-out-quad),
    background 0.2s var(--ease-in-out-quad), color 0.2s var(--ease-in-out-quad);

  &:hover {
    background: ${p =>
      p.hasError ? p.theme.colors.error : p.theme.colors.accent};
    color: ${p => p.theme.colors.background};
  }

  &[disabled] {
    cursor: not-allowed;
  }

  svg * {
    fill: ${p => p.theme.colors.background};
  }

`;

const BioText = styled.p`
  margin-top: 1em;
  font-size: 16px;
  line-height: 1.45;
  color: ${p => p.theme.colors.grey};

  a {
    color: ${p => p.theme.colors.grey};
    text-decoration: underline;
  }
`;